<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('faq') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin: 30px 10px;">
      <template v-if="isZhCn">
        <h1>常见问题</h1>
        <h2>• 我可以取消订单吗？</h2>
        <p>当然可以！任何取消费用均由住宿提供方决定，你可以在取消政策中查看。</p>

        <h2>• 取消订单会收取费用吗？</h2>
        <p>如果你的订单可免费取消，则无需支付取消费用。如果已经错过免费取消的最后期限或该订单不可退款，则会产生取消费用。</p>

        <h2>• “不退款”或“免费取消”是什么意思？</h2>
        <p>每间客房或住宿均拥有不同的政策（均由住宿设置）。</p>
        <p>不退款政策意味着若您决定取消或更改预订，则必须支付一笔费用。在预订过程中的相关条款和预订后的确认邮件均有提及该笔费用。</p>
        <p>免费取消意味着您可以在住宿所设定的期限内免费更改或取消您的预订（例如：“在x天前取消”或“在yy年mm月dd日hh:mm之前取消”）。在预订过程中的相关条款和预订后的确认邮件均有提及此期限。</p>

        <h2>• 如何获取客房或住宿设施的更多信息？</h2>
        <p>你可以在订单确认单中查看客房及住宿设施。</p>

        <h2>• 双人间和双床间有什么分别？</h2>
        <p>双人间配有一张双人床。双床间配有两张单人床。两者均可容纳二人入住。「双人/双床间」则可配备双人床或两张单人床。预订时请在「特殊要求」栏内写明您所需要的床型。</p>
      </template>
      <template v-else>
        <h1>FAQ</h1>
        <h2>• Can I cancel my booking?</h2>
        <p>Yes – any cancellation fees are determined by the property and listed in your cancellation policy.</p>

        <h2>• If I need to cancel my booking, will I pay a fee?</h2>
        <p>If you have a free cancellation booking, you won't pay a cancellation fee. If your booking isn't free to
          cancel anymore or is non-refundable, you may incur a cancellation fee.</p>

        <h2>• What do "non-refundable" and "free cancellation" mean?</h2>
        <p>Every room or property has an individual policy determined by the property.</p>
        <p>A “non-refundable” policy means a fee will apply if you decide to change or cancel your booking. This fee is
          mentioned during the booking process in the conditions and in the booking confirmation.</p>
        <p>A “free cancellation” policy means you can change or cancel a booking for free, as long as you do it within
          the time frame specified by the property (e.g. “Cancel up to x days” or “Cancel before mm/dd/yy hh:mm”). This
          is mentioned in the conditions during the booking process and in the booking confirmation.</p>

        <h2>• How do I get more info about the room or property's facilities?</h2>
        <p>You can find the room and property facilities in your booking confirmation.</p>

        <h2>• What is the difference between a Double Room and a Twin Room?</h2>
        <p>A Double Room has one double/full bed and a Twin Room has 2 twin beds. If a room is called Double/Twin, it
          can be set up for either type. You can specify your bed-type preference in the "Special Requests" box during
          the booking process.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
    }
  }
}
</script>

<style scoped>

</style>